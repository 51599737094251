// Bootstrap overwrite

// Enable / disable
$enable-gradients: false;
$enable-shadows: false;

// Theme
$primary: #979897 !default;
$secondary: #fafafa !default;
$light: #fff;

// Text color
$body-color: #565875 !default;
$headings-color: #000 !default;
// Background
$body-bg: #004E9E !default;

// Input
$input-box-shadow: none !default;
$input-focus-box-shadow: none !default;
$input-btn-focus-width: 0 !default;
$input-border-width: 1px !default;
$input-border-radius: 0 !default;
$input-border-radius-lg: 0 !default;
$input-border-color: #f1f1f9 !default;
$input-placeholder-color: #bbbbcf !default;

// Buttons
$btn-focus-box-shadow: none;
$btn-active-box-shadow: none;

// Fonts
$font-family-sans-serif:  "Barlow", "Helvetica Neue", Helvetica, Arial, sans-serif !default;
$font-family-serif:       Georgia, "Times New Roman", Times, serif !default;
